import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './DrinkList.css';

// Funktion zur Formatierung der Preise mit € vor der Zahl
const formatPrice = (price) => {
  return `€ ${price.toFixed(2).replace('.', ',')}`; // Manuelle Formatierung: € vor dem Betrag
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Funktion mit Timeout für Fetch-Request
const fetchWithTimeout = (url, options, timeout = 5000) => {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Timeout')), timeout)
    ),
  ]);
};

function DrinkList() {
  const [drinks, setDrinks] = useState([]);
  const { kategorie } = useParams();
  const [order, setOrder] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [notification, setNotification] = useState({ message: '', type: '', visible: false });
  const [pendingOrders, setPendingOrders] = useState(
    JSON.parse(localStorage.getItem('pendingOrders')) || []
  );
  const [isSubmitting, setIsSubmitting] = useState(false); // Ladezustand für den Button

  useEffect(() => {
    fetch(`https://bar.von-der-weide.de/api/drinks?kategorie=${kategorie}`)
      .then((response) => response.json())
      .then((data) => setDrinks(data));
  }, [kategorie]);

  // Bestellung in localStorage speichern
  const saveOrderToLocal = (order) => {
    const updatedPendingOrders = [...pendingOrders, order];
    localStorage.setItem('pendingOrders', JSON.stringify(updatedPendingOrders));
    setPendingOrders(updatedPendingOrders);

    setNotification({ message: 'Bestellung in Warteschlange gespeichert', type: 'queue', visible: true });
    setTimeout(() => setNotification({ ...notification, visible: false }), 2000);
    clearCurrentOrder(); // Bestellungsliste leeren, um neue Bestellung zu ermöglichen
  };

  // Erfolgreich gesendete Bestellung aus localStorage entfernen
  const removeOrderFromLocal = (orderIndex) => {
    const updatedPendingOrders = [...pendingOrders];
    updatedPendingOrders.splice(orderIndex, 1);
    localStorage.setItem('pendingOrders', JSON.stringify(updatedPendingOrders));
    setPendingOrders(updatedPendingOrders);
  };

  // Versuche, alle Bestellungen in der Warteschleife zu senden, ohne den Button zu sperren
  const retryPendingOrders = () => {
    pendingOrders.forEach((pendingOrder, index) => {
      submitOrder(pendingOrder, index, false); // Beim Wiederholen wird der Button nicht gesperrt
    });
  };

  const clearCurrentOrder = () => {
    setOrder([]);
    setTotalPrice(0);
  };

  // Funktion, um ein Getränk zur Bestellung hinzuzufügen
  const addToOrder = (drink) => {
    setOrder([...order, drink]);
    setTotalPrice(totalPrice + drink.price);
  };

  // Funktion, um ein Getränk aus der Bestellung zu entfernen
  const removeFromOrder = (indexToRemove) => {
    const newOrder = order.filter((_, index) => index !== indexToRemove);
    const removedItem = order[indexToRemove];
    setOrder(newOrder);
    setTotalPrice(totalPrice - removedItem.price);
  };

  // Funktion, um die Bestellung zu übermitteln
  // Der Parameter `lockButton` steuert, ob der Button gesperrt wird (nur bei manueller Bestellung)
  const submitOrder = (order, orderIndex = null, lockButton = true) => {
    if (lockButton) setIsSubmitting(true); // Ladezustand aktivieren, nur bei manueller Bestellung

    fetchWithTimeout('https://bar.von-der-weide.de/api/submitOrder', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ order }),
    })
      .then((response) => {
        if (response.ok) {
          if (orderIndex !== null) {
            removeOrderFromLocal(orderIndex); // Erfolgreich übermittelte Bestellung entfernen
          }
          setNotification({
            message: 'Bestellung erfolgreich übermittelt!',
            type: 'success',
            visible: true,
          });
          clearCurrentOrder(); // Bestellungsliste leeren
        } else {
          if (orderIndex === null) {
            saveOrderToLocal(order); // Bei Fehler in die Warteschleife speichern
          }
          setNotification({
            message: 'Fehler beim Übermitteln der Bestellung! Erneuter Versuch...',
            type: 'error',
            visible: true,
          });
        }
        setTimeout(() => setNotification({ ...notification, visible: false }), 2000);
        if (lockButton) setIsSubmitting(false); // Ladezustand zurücksetzen, nur bei manueller Bestellung
      })
      .catch((error) => {
        console.error('Netzwerkfehler:', error.message);
        if (error.message === 'Failed to fetch' || error.message === 'Timeout') {
          // Bei Netzwerkproblemen in Warteschleife speichern
          if (orderIndex === null) {
            saveOrderToLocal(order);
          }
          setNotification({
            message: 'Keine Verbindung. Bestellung wird lokal gespeichert.',
            type: 'queue',
            visible: true,
          });
          setTimeout(() => setNotification({ ...notification, visible: false }), 2000);
        }
        if (lockButton) setIsSubmitting(false); // Ladezustand zurücksetzen, nur bei manueller Bestellung
      });
  };

  // Automatisch alle 5 Sekunden versuchen, Bestellungen erneut zu senden
  useEffect(() => {
    const intervalId = setInterval(() => {
      retryPendingOrders(); // Versuche, die in der Warteschleife befindlichen Bestellungen zu senden
    }, 5000); // Versuche alle 5 Sekunden, wartende Bestellungen zu senden

    return () => clearInterval(intervalId); // Aufräumen, wenn die Komponente unmontiert wird
  }, [pendingOrders]);

  return (
    <div>
      {/* Logo und Überschrift im gleichen flexiblen Container */}
      <div className="header-container">
        <img src="https://www.von-der-weide.de/img/logo.jpg" alt="Von der Weide Logo" className="logo" />
        <h1 className="page-header">Kasse - {capitalizeFirstLetter(kategorie)}</h1>
      </div>

      <div className="container">
        <div className="drinks-list">
          <div className="drinks-grid">
            {drinks.map((drink) => (
              <div
                className="drink-item"
                key={drink.id}
                onClick={() => addToOrder(drink)}
                style={{
                  cursor: 'pointer',
                  backgroundImage: drink.image ? `url(${drink.image})` : 'none',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  color: '#fff',
                  position: 'relative',
                }}
              >
                <div className="overlay"></div>
                <div className="card-body">
                  <h5 className="card-title">{drink.name}</h5>
                  <p className="card-text">{drink.info}</p>
                  <p className="card-text">{formatPrice(drink.price)}</p> {/* Preisformatierung */}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="order-summary">
          <h2>Bestellung</h2>
          <div className="order-items">
            <ul className="list-group">
              {order.map((item, index) => (
                <li className="list-group-item d-flex justify-content-between align-items-center" key={index}>
                  <span>{item.name} - {formatPrice(item.price)}</span> {/* Preisformatierung */}
                  <button
                    className="btn btn-danger btn-sm"
                    style={{ width: '30px', height: '30px', padding: '0', textAlign: 'center', lineHeight: '30px' }}
                    onClick={() => removeFromOrder(index)}
                  >
                    &times;
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <h3 className="mt-3">Gesamtpreis: {formatPrice(totalPrice)}</h3> {/* Gesamtpreisformatierung */}
          <button 
            className="btn btn-success mt-2" 
            onClick={() => submitOrder(order, null, true)}  // Button aktiviert den Ladezustand
            disabled={isSubmitting}  // Button wird deaktiviert während der Bestellung
          >
            {isSubmitting ? (
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> 
            ) : (
              'Bestellung abschließen'
            )}
          </button>
        </div>
      </div>

      {/* Benachrichtigungs-Overlay */}
      {notification.visible && (
        <div className={`notification ${notification.type}`}>
          {notification.message}
        </div>
      )}
    </div>
  );
}

export default DrinkList;